<div class="container mx-auto mt-8 flex flex-col justify-center bg-white items-center p-4 md:p-12 text-center">
  <!-- Delete Confirmation Dialog -->
  <div *ngIf="isDeleteDialog" class="w-full max-w-md">
    <div class="text-center mb-6">
      <h2 class="text-xl md:text-2xl font-bold text-red-600 mb-4">Confirm Delete</h2>
      <p class="text-gray-700 mb-4">Are you sure you want to delete this document?</p>
      <p class="text-sm text-gray-600 mb-6">This action cannot be undone. The document will be permanently deleted from our servers.</p>
    </div>

    <div class="flex flex-col space-y-4">
      <!-- Warning message -->
      <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
        <div class="flex">
          <div class="ml-3">
            <p class="text-sm text-yellow-700">
              Warning: After deletion, you'll need to start a new session to generate another document.
            </p>
          </div>
        </div>
      </div>

      <!-- Confirmation checkbox -->
      <div class="flex items-center space-x-2">
        <input 
          type="checkbox" 
          id="confirmDelete" 
          [(ngModel)]="confirmDelete"
          class="form-checkbox h-4 w-4 text-red-600"
        >
        <label for="confirmDelete" class="text-sm text-gray-700">
          I understand that this action is permanent
        </label>
      </div>

      <!-- Action buttons -->
      <div class="flex space-x-4">
        <button
          (click)="onCancelDelete()"
          class="flex-1 py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 focus:outline-none transition-colors"
        >
          Cancel
        </button>
        <button
          (click)="onConfirmDelete()"
          [disabled]="!confirmDelete"
          [class.opacity-50]="!confirmDelete"
          class="flex-1 py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700 focus:outline-none transition-colors disabled:cursor-not-allowed"
        >
          Delete Document
        </button>
      </div>
    </div>
  </div>

  <!-- Original Payment/Terms Dialog -->
  <div *ngIf="!isDeleteDialog">
    <!-- Selected Scenes -->
    <div>
      <h3 class="text-lg md:text-xl font-bold mb-4">Selected Scenes:</h3>
      <ul>
        <li *ngFor="let text of data.selected" class="list-none mb-2 font-bold text-base md:text-lg">
          {{ text.sceneNumberText }} {{ text.text }}
        </li>
      </ul>
      <h2 class="text-xl md:text-2xl font-bold mt-4">Total: FREE FOR BETA</h2>
      <h3>Note: Stripe will process a fake payment to your card for testing purposes.</h3>
      <h3>Your card will not be charged</h3>
    </div>

    <!-- Checkbox for Terms and Conditions -->
    <div class="mt-6">
      <div class="mt-4 md:mt-8 p-2 md:p-4">
        <button (click)="this.proceedToCheckout(true)" 
                [class.bg-gray-200]="!agreeToTerms" 
                [class.bg-blue-500]="agreeToTerms"
                class="py-2 px-4 rounded hover:bg-blue-600 focus:outline-none w-full md:w-auto" 
                [disabled]="!agreeToTerms">
          Get My Sides
        </button>
      </div>
      <div class="flex flex-col items-start">
        <button (click)="showTerms = !showTerms" class="py-2 px-4 text-sm text-blue-500 hover:underline">
          {{ showTerms ? 'Hide Terms of Service' : 'Show Terms of Service' }}
        </button>
        <label class="mt-2 md:mt-0">
          <input type="checkbox" name="terms" [(ngModel)]="agreeToTerms" required>
          I agree to the Terms of Service.
        </label>
        <div *ngIf="showTerms" class="terms-of-service text-sm mt-2">
          <!-- ... existing terms content ... -->
        </div>
      </div>
    </div>
  </div>
</div>