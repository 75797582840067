<main class="max-w-[90rem] mx-auto mt-8 fadeInOut">
  <header class="bg-white py-8 max-w-[90rem] mx-auto overflow-hidden blob-2">
    <div class="container mx-auto flex flex-col lg:flex-row items-center justify-between px-4 lg:px-8">
      
      <!-- Hero Text Section -->
      <div class="text-center lg:text-left xl:w-1/2 lg:w-3/4 mx-auto">
        <div class="py-8 text-left">
          <h1 class="text-4xl lg:text-8xl md:text-6xl font-bold text-black">Sides-Ways</h1>
          <h2 class="text-4xl font-semibold leading-normal text-dark-gray mb-4">Free Open Beta</h2>
        </div>

        <div class="py-8 text-left">
          <h2 class="text-3xl font-semibold leading-normal text-dark-gray">
            Secure
          </h2>
          <h2 class="text-3xl font-semibold leading-normal text-dark-gray">
            Set-Ready 
          </h2>
          <h2 class="text-3xl font-semibold leading-normal text-dark-gray">
            Sides in Seconds
          </h2>
        </div>

        <div class="mb-6 mt-8 py-8 text-3xl font-semibold leading-normal text-left leading-loose text-dark-gray">
          <h2>We Make Sides</h2>
          <h2 class="italic">That's it.</h2>
        </div>

        <div class="flex flex-col lg:flex-row lg:space-x-4 justify-center">
          <!-- Authentication Section -->
  
            <!-- File Upload Button (Only visible when authenticated) -->
            <button mat-raised-button color="warn" (click)="fileInput.click()"
              class="text-white py-8 font-semibold px-8 rounded-md mb-4 lg:mb-0 custom-button">
              Upload Script PDF
            </button>
  

          @if (this.underConstruction) {
          <button mat-raised-button class="bg-soft-red text-xl text-black py-3 px-8 rounded-md mb-4 lg:mb-0"
            (click)="skipUploadForTest()">skipUploadForTest</button>
          }
          <input hidden (change)="handleFileInput($event.target.files)" #fileInput type="file" id="file"
            accept="application/pdf">
        </div>
      </div>

      <!-- Hero Image Section -->
      <div class="md:w-1/2 lg:mt-0">
        <img src="assets/icons/logoFlat.png" alt="Hero Image" class="w-full rounded-lg">
      </div>

    </div>
  </header>

  <section class="about-product-section max-w-[80rem] mx-auto">
    <app-about-item-grid></app-about-item-grid>
  </section>
  <section class="customers-section max-w-[80rem] mx-auto">
    <app-carousel></app-carousel>
  </section>
  <section class="feature-section max-w-[80rem] mx-auto">
    <app-spinning-bot [title]="'fake name'"></app-spinning-bot>
  </section>
</main>